import { useState } from "react";
import { appClient } from "../../../../utils/axios-utils";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAdGenerator } from "../context/adGeneration";
import { useAuth } from "../../../../context/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { useTextAdGenerator } from "../TextAd/context/textAdGeneration";
import { getSearchParams } from "../../../../utils/helper";
import defaultPersonas from "../../../../utils/defaultPersonas"

const getUserAdId = (data) => {
  const config = {
    url: `/new-text-ad/add-details`,
    method: "POST",
    data,
  };

  return appClient(config);
};

const getWebsiteMeta = (data) => {
  const config = {
    url: `/new-text-ad/get-processed-state`,
    method: "POST",
    data,
  };

  return appClient(config);
};

const GenerateAd = (data) => {
  const config = {
    url: `/new-text-ad/add-ad-details`,
    method: "POST",
    data,
  };

  return appClient(config);
};

const getGenerate = (data) => {
  const config = {
    url: `/new-text-ad/get-generation-process`,
    method: "POST",
    data,
  };

  return appClient(config);
};

const getAdGroups = (data) => {
  const config = {
    url: `/new-text-ad/get-ad-groups`,
    method: "POST",
    data,
  };

  return appClient(config);
};

const getSingleAdGroups = (data) => {
  const config = {
    url: `/new-text-ad/get-single-group`,
    method: "POST",
    data,
  };

  return appClient(config);
};

const adDetails = (data) => {
  const config = {
    url: `/new-text-ad/get-ad-details`,
    method: "POST",
    data,
  };

  return appClient(config);
};
const updateAdDetails = (data) => {
  const config = {
    url: `/new-text-ad/update-details`,
    method: "POST",
    data,
  };

  return appClient(config);
};

const getGoogleConnected = async (data) => {
  const config = {
    url: "/admanagement/get-connected-accounts",
    method: "GET",
  };

  return appClient(config);
};
const getHistory = () => {
  const config = {
    url: `/new-text-ad/get-all-ads`,
    method: "GET"
  };

  return appClient(config);
};
export const useTextAdGenInfo = () => {
  const navigate = useNavigate();
  const { adGeneratorState, adGeneratorDispatch } = useAdGenerator();
  const location = useLocation();

  // const { textAdGeneratorState, textAdGeneratorDispatch } = useTextAdGenerator();
  // const [wesbiteMetaLoading, setWesbiteMetaLoading] = useState(false);

  const { name, description, productDetails, colors, images } =
    adGeneratorState.brandDetails;

  const { authState } = useAuth();

  const { mutate: getUserAdIdMutate, isLoading: isUserAdIdLoading } =
    useMutation(getUserAdId, {
      onSuccess: (data) => {
        adGeneratorDispatch({
          type: "SET_GENERATED_AD_ID",
          value: data.userAdId,
        });
      },
      onError: (err) => {
        console.log(err);
      },
      onMutate: () => { },
    });

  const { mutate: getWebsiteMetaMutate, isLoading: isWebsiteMetaLoading } =
    useMutation(getWebsiteMeta, {
      onSuccess: (data, variables) => {
        // console.log(data, !data.processed);
        if (!data.processed) {
          setTimeout(() => {
            getWebsiteMetaMutate({
              userAdId: adGeneratorState.generatedAdId,
              projectId: authState.defaultProjectInfo.projectId,
            });
          }, 2000);
        } else {
          adGeneratorDispatch({
            type: "SET_WEBSITE_META_LOADING",
            value: false,
          });

          adGeneratorDispatch({
            type: "SET_KEYWORD_SUGGESTION",
            value: data.brandDetails.seedKeywords,
          });
          if (!data.brandDetails.personas) {
            adGeneratorDispatch({
              type: "SET_OCCUPATION_ITEM",
              value: defaultPersonas
            })
          } else {
            adGeneratorDispatch({
              type: "SET_OCCUPATION_ITEM",
              value: data.brandDetails.personas,
            });
          }

          setTimeout(() => {
            if (!name) {
              adGeneratorDispatch({
                type: "SET_BRAND_DETAILS",
                value: {
                  ...adGeneratorState.brandDetails,
                  name: data.brandDetails.brandName
                    ? data.brandDetails.brandName
                    : "",
                },
              });
            }
          }, 500);

          setTimeout(() => {
            if (!description) {
              adGeneratorDispatch({
                type: "SET_BRAND_DETAILS",
                value: {
                  ...adGeneratorState.brandDetails,
                  name: data.brandDetails.brandName
                    ? data.brandDetails.brandName
                    : "",
                  description: data.brandDetails.aboutBrand
                    ? data.brandDetails.aboutBrand
                    : "",
                },
              });
            }
          }, 1000);

          setTimeout(() => {
            if (!productDetails) {
              adGeneratorDispatch({
                type: "SET_BRAND_DETAILS",
                value: {
                  ...adGeneratorState.brandDetails,
                  name: data.brandDetails.brandName
                    ? data.brandDetails.brandName
                    : "",
                  description: data.brandDetails.aboutBrand
                    ? data.brandDetails.aboutBrand
                    : "",
                  productDetails: data.brandDetails.pageDetailSummary
                    ? data.brandDetails.pageDetailSummary
                    : "",
                },
              });
            }
          }, 1500);

          // adGeneratorDispatch({
          //   type: "SET_BRAND_DETAILS", value: {
          //     ...adGeneratorState.brandDetails,
          //     description: data.brandDetails.aboutBrand ? data.brandDetails.aboutBrand : "",
          //     productDetails: data.brandDetails.pageDetailSummary,
          //   }
          // })
        }
      },
      onError: (err) => {
        console.log(err);
      },
      onMutate: () => { },
    });

  const { mutate: getGenerateProcess, isLoading: isGenerateProcessLoading } =
    useMutation(getGenerate, {
      onSuccess: (data) => {
        const allCompleted = data.processed.every((task) => task.isCompleted);
        const searchParamFromState = getSearchParams(location.search);

        const { id } = searchParamFromState;

        if (data.notSucceeded === true) {
          adGeneratorDispatch({ type: "SET_WENT_WRONG", value: true });
        } else {
          if (!allCompleted) {
            data.processed
              .map((item, index) => ({ ...item, index }))
              .forEach(({ id, name, isCompleted }) => {
                if (isCompleted === true) {
                  adGeneratorDispatch({
                    type: "UPDATE_PROCESSING_STATUS",
                    value: { id: id - 1, value: true },
                  });
                }
              });
            setTimeout(() => {
              getGenerateProcess({
                userAdId: id,
              });
            }, 3000);
          } else {
            getAdGroupsData({
              userAdId: id,
            });
          }
        }
      },
      onError: (err) => {
        console.log(err);
      },
      onMutate: () => { },
    });

  const { mutate: generateAdMutate, isLoading: isGenerateAdLoading } =
    useMutation(GenerateAd, {
      onSuccess: (data) => {
        if (data.userAdId) {
          navigate(`/adgenerator/text-ad/result?id=${data.userAdId}`);
        }
      },
      onError: (err) => {
        console.log(err);
      },
      onMutate: () => { },
    });

  const { mutate: getAdGroupsData, isLoading: isAdGroupsDataLoading } =
    useMutation(getAdGroups, {
      onSuccess: (data) => {
        adGeneratorDispatch({
          type: "SET_GENERATION_PROCESS",
          value: data,
        });
      },
      onError: (err) => {
        console.log(err);
      },
      onMutate: () => { },
    });

  const {
    mutate: getSingleAdGroupsData,
    isLoading: isSingleAdGroupsDataLoading,
  } = useMutation(getSingleAdGroups, {
    onSuccess: (data) => {
      console.log(data,'data')
      adGeneratorDispatch({
        type: "SET_ADGROUP_ITEM",
        value: data,
      });
    },
    onError: (err) => {
      console.log(err);
    },
    onMutate: () => { },
  });

  const { mutate: getAdDetails, isLoading: isAdDetailsLoading } = useMutation(
    adDetails,
    {
      onSuccess: (data) => {
        adGeneratorDispatch({
          type: "SET_AD_DETAILS",
          value: data,
        });
      },
      onError: (err) => {
        console.log(err);
      },
      onMutate: () => { },
    }
  );
  const { mutate: getUpdateAd, isLoading: isUpdateAdLoading } = useMutation(
    updateAdDetails,
    {
      onSuccess: (data) => {
        navigate(`/adgenerator/text-ad/result?id=${data.userAdId}`);
        adGeneratorDispatch({
          type: "SET_GENERATION_PROCESS",
          value: null,
        });
      },
      onError: (err) => {
        console.log(err);
      },
      onMutate: () => { },
    }
  );
  
  const { mutate: setGoogleConnected, isLoading: isGoogleConnectedLoading } =
    useMutation(getGoogleConnected, {
      onSuccess: (data) => {
        // textAdGeneratorDispatch({type: "SET_GOOGLE_CONNECTED_DATA", value: data})
        data.length > 0
          ? navigate(
            `/admanagement-new/ad-campaigns/new-campaign?id=${adGeneratorState.adGroupItem.adGroupRow?.id}`
          )
          : navigate(
            `/admanagement-new/ad-campaigns?id=${adGeneratorState.adGroupItem.adGroupRow?.id}`
          );
      },
      onError: (err) => { },
    });

  const { mutate: getTextAdHistory, isLoading: isTextAdHistoryLoading } =
    useMutation(getHistory, {
      onSuccess: (data) => {
        adGeneratorDispatch({ type: "SET_HISTORY_DATA", value: data })
      },
      onError: (e) => { },
    });

  return {
    getUserAdIdMutate,
    isUserAdIdLoading,

    getWebsiteMetaMutate,
    isWebsiteMetaLoading,

    generateAdMutate,
    isGenerateAdLoading,
    getGenerateProcess,
    isGenerateProcessLoading,
    getAdGroupsData,
    isAdGroupsDataLoading,
    getSingleAdGroupsData,
    isSingleAdGroupsDataLoading,
    getAdDetails,
    isAdDetailsLoading,
    getUpdateAd,
    isUpdateAdLoading,

    setGoogleConnected,
    isGoogleConnectedLoading,

    getTextAdHistory,
    isTextAdHistoryLoading
  };
};
