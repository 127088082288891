import React from "react";
import { useReducer, createContext, useContext } from "react";

import produce from "immer";
import moment from "moment";
import { extendMoment } from "moment-range";

const extendedMoment = extendMoment(moment);
const today = extendedMoment();

const initialState = {
  connectedAdAccount: null,
  selectAdAccount: false,
  manageAccount: false,
  isIntegrationId: null,
  listAllAdAccounts: [],
  adAccountDropDownOpen: false,
  selectedAdType: null,
  isSelectAdTypeToggle: false,
  selectedAdAccount: null,
  currentCampaignStep: 2,
  isErrorMsg: null,
  campaignName: null,
  adGroupList: [],
  isDestinationUrl: null,
  isDestinationError: null,
  isBudgetData: null,
  openedStrategyDropDown: false,
  selectedStrategy: null,
  isInputPage: true,
  keywordsList: [],
  openedKeywordTypeDropDown: null,
  openedLocationSuggestion: false,
  locationsList: [],
  openedLanguageSuggestion: false,
  languageList: [],
  activeAdInputTab: 1,
  isCreateAdHeadline: [],
  isCreateAdDescription: [],
  isTransformedKeyword: [],
  isSingleAdGroupData: [],
  isCreateCampaign: {},
  isCampaignPublished: false,
  isCustomerId: null,
  connectByProjectId: null,
  dateStartEnd: extendedMoment.range(
    today.clone().subtract(3, "months"),
    today.clone()
  ),
  isMetricChart: [],
  isCampaignSummary: [],
  isStatusDetail: "all",
};

const adManagementTypeReducer = produce((draft, action) => {
  switch (action.type) {
    case "SET_CONNECTED_DATA":
      draft.connectedAdAccount = action.value;
      break;
    case "SET_SELECT_AD_ACCOUNT":
      draft.selectAdAccount = action.value;
      break;
    case "SET_MANAGE_ACCOUNT":
      draft.manageAccount = action.value;
      break;
    case "SET_INTEGRATION_ID":
      draft.isIntegrationId = action.value;
      break;
    case "SET_LIST_AD_ACCOUNTS":
      draft.listAllAdAccounts = action.value;
      break;
    case "SET_ADACCOUNT_DROPDOWN_OPEN":
      draft.adAccountDropDownOpen = action.value;
      break;
    case "SET_SELECTED_ADTYPE":
      draft.selectedAdType = action.value;
      break;
    case "SET_SELECTAD_TYPE_TOGGLE":
      draft.isSelectAdTypeToggle = action.value;
      break;
    case "SET_SELECTED_ACCOUNT":
      draft.selectedAdAccount = action.value;
      break;
    case "SET_CURRENT_CAMPAIGN_STEP":
      draft.currentCampaignStep = action.value;
      break;
    case "SET_ERROR_MESSAGE":
      draft.isErrorMsg = action.value;
      break;
    case "SET_CAMPAIGN_NAME":
      draft.campaignName = action.value;
      break;
    case "SET_ADGROUP_LIST":
      draft.adGroupList = action.value;
      break;
    case "SET_DESTINATION_URL":
      draft.isDestinationUrl = action.value;
      break;
    case "SET_DESTINATION_ERROR":
      draft.isDestinationError = action.value;
      break;
    case "SET_BUDGET_DATA":
      draft.isBudgetData = action.value;
      break;
    case "SET_OPENED_STRATEGY_DROPDOWN":
      draft.openedStrategyDropDown = action.value;
      break;
    case "SET_SELECTED_STRATEGY":
      draft.selectedStrategy = action.value;
      break;
    case "SET_INPUT_PAGE":
      draft.isInputPage = action.value;
      break;
    case "SET_KEYWORDS_LIST":
      draft.keywordsList = action.value;
      break;
    case "SET_OPENED_KEYWORD_TYPE_DROPDOWN":
      draft.openedKeywordTypeDropDown = action.value;
      break;
    case "SET_OPENED_LOCATION_SUGGESTION":
      draft.openedLocationSuggestion = action.value;
      break;
    case "SET_LOCATIONS_LIST":
      draft.locationsList = action.value;
      break;
    case "SET_OPENED_LANGUAGE_SUGGESTION":
      draft.openedLanguageSuggestion = action.value;
      break;
    case "SET_LANGUAGE_LIST":
      draft.languageList = action.value;
      break;
    case "SET_ACTIVE_ADINPUT_TAB":
      draft.activeAdInputTab = action.value;
      break;
    case "SET_CREATE_AD_HEADLINE":
      draft.isCreateAdHeadline = action.value;
      break;
    case "SET_CREATE_AD_DESCRIPTION":
      draft.isCreateAdDescription = action.value;
      break;
    case "SET_TRANSFORMED_KEYWORD":
      draft.isTransformedKeyword = action.value;
      break;
    case "SET_SINGLE_AD_GROUP":
      draft.isSingleAdGroupData = action.value;
      break;
    case "SET_CREATE_CAMPAIGN":
      draft.isCreateCampaign = action.value;
      break;
    case "SET_CAMPAIGN_PUBLISHED":
      draft.isCampaignPublished = action.value;
      break;
    case "SET_CUSTOMER_ID":
      draft.isCustomerId = action.value;
      break;
    case "SET_CONNECTED_ACC_BY_PROJECTID":
      draft.connectByProjectId = action.value;
      break;
    case "SET_DATE_START_END":
      draft.dateStartEnd = action.value;
      break;
    case "SET_METRIC_CHART":
      draft.isMetricChart = action.value;
      break;
    case "SET_CAMPAIGN_SUMMARY":
      draft.isCampaignSummary = action.value;
      break;
    case "SET_STATUS_DETAIL":
      draft.isStatusDetail = action.value;
      break;
    default:
      break;
  }
});

const AdManagementReducer = () => {
  return useReducer(adManagementTypeReducer, initialState);
};

const AdManagementNewContext = createContext();

export const AdManagementNewProvider = ({ children }) => {
  const [state, dispatch] = AdManagementReducer();
  const value = {
    newAdManagementState: state,
    newAdManagementDispatch: dispatch,
  };

  return (
    <AdManagementNewContext.Provider value={value}>
      {children}
    </AdManagementNewContext.Provider>
  );
};

export const useAdIntegration = () => {
  return useContext(AdManagementNewContext);
};
