import React, { useState, useEffect } from "react";
import {
  Wrapper,
  HeaderContainer,
  HeaderLeft,
  HeaderRight,
  ContentContainer,
  GeneratingUi,
  ResultsContainer,
  CardsRow,
  AdCard,
  CardRowHeader,
  TypeOfAd,
  CardRowContent,
  TemplateDetailsContainer,
  TemplateDetailsWrapper,
  TemplateDetailsHeader,
  TemplateDetailsContent,
  EditTemplateButton,
  ShowMore,
} from "./index.styled";
import BackArrow from "../../../../../../../../media/Dashboard/backarrow.svg";
import AiLoader from "../../../../../../../../media/Dashboard/AiLoader.webp";
import DummyAdImage from "../../../../../../../../media/Dashboard/dummy-generated-image-ad.svg";
import CloseImage from "../../../../../../../../media/Dashboard/CloseDark.svg";
import { useNavigate } from "react-router-dom";
import { useImageAdGenInfo } from "../../../../../hooks/useImageAdGenInfo";
import { getSearchParams } from "../../../../../../../../utils/helper";
import { useImageAdGenerator } from "../../../../context/imageAdGeneration";
import { useAdGenerator } from "../../../../../context/adGeneration";
import imagIcon from "../../../../../../../../media/Dashboard/Picture.svg";
import { useAuth } from "../../../../../../../../context/auth";

// const tempRows = [
//   {
//     name: "Variation",
//     adType: "Stock Image",
//     ads: [
//       {
//         imgUrl: DummyAdImage,
//         id: 0,
//       },
//       {
//         imgUrl: DummyAdImage,
//         id: 1,
//       },
//       {
//         imgUrl: DummyAdImage,
//         id: 2,
//       },
//     ],
//   },
// ];

const Results = () => {
  //   const [resultsData, setResultsData] = useState(tempRows);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [expandedRows, setExpandedRows] = useState({});

  const {
    getTemplateVariation,
    getInsertTemplate,
    isTemplateVariationLoading,
  } = useImageAdGenInfo();
  const {
    adGeneratorState: { templateVariations },
    adGeneratorDispatch,
  } = useAdGenerator();
  const { authState, authDispatch } = useAuth();

  const params = getSearchParams(window.location.search);

  // useEffect(() => {
  //     setInterval(() => {
  //         setResultsData([...tempRows, ...resultsData])
  //     }, 2000);
  // }, [])
  const handleShowMoreToggle = (index) => {
    setExpandedRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [index]: !prevExpandedRows[index],
    }));
  };

  const navigate = useNavigate();
  useEffect(() => {
    adGeneratorDispatch({
      type: "SET_TEMPLATE_VARIATIONS",
      value: null,
    });
    getTemplateVariation({
      campaignId: params.id,
    });
    adGeneratorDispatch({
      type: "SET_CURRENT_INPUT_PAGE_INDEX",
      value: 0,
    });
  }, [params.id]);
  console.log(isTemplateVariationLoading, templateVariations);
  return (
    <Wrapper>
      {selectedTemplate.html && (
        <TemplateDetailsContainer>
          <TemplateDetailsWrapper>
            <TemplateDetailsHeader>
              <h2>Details</h2>
              <img src={CloseImage} onClick={() => setSelectedTemplate({})} />
            </TemplateDetailsHeader>
            <TemplateDetailsContent>
              <AdCard>
                <div
                  className="outerHtml"
                  dangerouslySetInnerHTML={{ __html: selectedTemplate.html }}
                />
              </AdCard>
              <p className="detailText">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Pellentesque a venenatis ligula. Sed id lacus lobortis, varius
                libero vitae, tristique justo. Cras mattis ante congue, vehicula
                ante laoreet, hendrerit enim. Donec ac nunc at leo venenatis
                vestibulum. Morbi varius, libero sit amet blandit viverra, justo
                ex tempus eros, ut dignissim ex ligula id dui. Aliquam neque
                sem, sollicitudin et eleifend eu, feugiat a arcu. Sed in
                lobortis velit. Donec viverra, nisl sed dictum tincidunt, sapien
                eros laoreet erat, et maximus mi tortor nec justo. Nullam
                sodales neque et convallis posuere. Nam lobortis, nisl a sod
              </p>
              <EditTemplateButton
                onClick={() => {
                  navigate(
                    `/adgenerator/image-ad/result?id=${params.id}&variationId=${selectedTemplate.variationId}`
                  );
                  getInsertTemplate({
                    variationId: selectedTemplate.variationId,
                    html: selectedTemplate.html,
                    brandName: authState.defaultProjectInfo.projectName,
                  });
                }}
              >
                Edit
              </EditTemplateButton>
            </TemplateDetailsContent>
          </TemplateDetailsWrapper>
        </TemplateDetailsContainer>
      )}
      <HeaderContainer>
        <HeaderLeft>
          {/* <img src={BackArrow} />  */}
          <p>Results</p>
        </HeaderLeft>
        <HeaderRight>
          <span>Not satisfied with the design?</span>
          <button>Generate New</button>
        </HeaderRight>
      </HeaderContainer>
      <ContentContainer>
        {!templateVariations && (
          <GeneratingUi>
            <img src={AiLoader} />
            <p>Generating..</p>
          </GeneratingUi>
        )}

        {templateVariations && templateVariations.length == 0 && (
          <GeneratingUi>
            <p className="tryAgain">
              Image ad generation failed.{" "}
              <span onClick={() => navigate(`/adgenerator/image-ad`)}>
                Generate again
              </span>
            </p>
          </GeneratingUi>
        )}

        <ResultsContainer>
          {templateVariations &&
            templateVariations?.map((adRow, rowIndex) => {
              const isExpanded = expandedRows[rowIndex];

              return (
                <CardsRow>
                  <CardRowHeader>
                    <p>Variation {rowIndex + 1}</p>
                    <TypeOfAd>
                      <img src={imagIcon} />
                      Stock Image
                    </TypeOfAd>
                  </CardRowHeader>
                  <CardRowContent>
                    {adRow
                      .slice(0, isExpanded ? adRow.length : 3)
                      .map((ad, adIndex) => (
                        // <AdCard
                        //   key={adIndex}
                        //   onClick={() => setSelectedTemplate(ad)}
                        // >
                        //   <div
                        //     className="outerHtml"
                        //     dangerouslySetInnerHTML={{ __html: ad.html }}
                        //   />
                        // </AdCard>
                        <div
                          key={adIndex}
                          onClick={() => setSelectedTemplate(ad)}
                          className="outerHtml"
                          dangerouslySetInnerHTML={{ __html: ad.html }}
                        />
                      ))}
                  </CardRowContent>
                  {adRow.length > 3 && (
                    <ShowMore>
                      <p onClick={() => handleShowMoreToggle(rowIndex)}>
                        {isExpanded ? "Show less" : "Show more"}
                      </p>
                    </ShowMore>
                  )}
                </CardsRow>
              );
            })}
        </ResultsContainer>
      </ContentContainer>
    </Wrapper>
  );
};

export default Results;
