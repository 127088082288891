import React, { useEffect, useRef, useState } from "react";
import PreviewImage from "../../../../../../../../media/Dashboard/send-square.svg";
import CaptionImage from "../../../../../../../../media/Dashboard/text-block.svg";
import TextImage from "../../../../../../../../media/Dashboard/smallcaps.svg";
import ImageImage from "../../../../../../../../media/Dashboard/send-2.svg";
import AnimateImage from "../../../../../../../../media/Dashboard/video-square.svg";
import GalleryImage from "../../../../../../../../media/Dashboard/gallery.svg";
import ImageAdResultDummy from "../../../../../../../../media/Dashboard/ImageAdResultDummy.svg";

import {
  Wrapper,
  TemplateContainer,
  TemplateWrapper,
  EditContainer,
  NavigationComponent,
  NavElementContainer,
  Divider,
  EditComponent,
  SaveButton,
} from "./index.styled";
import { useImageAdGenerator } from "../../../../context/imageAdGeneration";
import Preview from "./components/preview";
import Caption from "./components/caption";
import Texts from "./components/texts";
import Images from "./components/image";
import Cta from "./components/cta";
import Color from "./components/color";
import Animate from "./components/Animate";
import { getSearchParams } from "../../../../../../../../utils/helper";
import { useImageAdGenInfo } from "../../../../../hooks/useImageAdGenInfo";
import { useAdGenerator } from "../../../../../context/adGeneration";
import { parseHTML } from "../../../../../../../../utils/parseHtml";
import { v4 as uuidv4 } from "uuid";
import { useAuth } from "../../../../../../../../context/auth";

const EditResult = () => {
  const { imageAdGeneratorState, imageAdGeneratorDispatch } =
    useImageAdGenerator();
  const {
    adGeneratorState: {
      updatetemplate,
      inputFields,
      rawTemplate,
      selectedProject,
    },
    adGeneratorDispatch,
  } = useAdGenerator();
  const { getTemplateDetail, getInsertTemplate } = useImageAdGenInfo();
  const { authState, authDispatch } = useAuth();
  const [savedState, setSavedState] = useState(false);
  const params = getSearchParams(window.location.search);
  const parsedHtmlRef = useRef(null);

  const NavElementsData = [
    {
      name: "Preview",
      imgUrl: PreviewImage,
    },
    {
      name: "",
      imgUrl: null,
    },
    {
      name: "Caption",
      imgUrl: CaptionImage,
    },
    {
      name: "Texts",
      imgUrl: TextImage,
    },
    {
      name: "Image",
      imgUrl: ImageImage,
    },
    // {
    //   name: "CTA",
    //   imgUrl: AnimateImage,
    // },
    {
      name: "Color",
      imgUrl: PreviewImage,
    },
    // {
    //   name: "Animate",
    //   imgUrl: GalleryImage,
    // },
  ];

  const renderEditUi = () => {
    switch (imageAdGeneratorState.curentDetailsTab) {
      case "Preview":
        return <Preview />;

      case "Caption":
        return <Caption />;

      case "Texts":
        return <Texts />;

      case "Image":
        return <Images />;

      // case "CTA":
      //   return <Cta />;

      case "Color":
        return <Color />;

      // case "Animate":
      //   return <Animate />;

      default:
        break;
    }
  };

  const extractFields = (data) => {
    parsedHtmlRef.current = parseHTML(data.html);
    const htmlContent = parsedHtmlRef.current;
    const fields = [];

    if (htmlContent.querySelector("[scale]")) {
      let templateScaleVal = htmlContent
        .querySelector("[scale]")
        .getAttribute("scale");
      // console.log("scale attr", templateScaleVal);
      adGeneratorDispatch({
        type: "SET_SCALE_VALUE",
        value: Number(templateScaleVal),
      });
    }

    htmlContent.querySelectorAll("*").forEach((element) => {
      const attributes = element.attributes;
      for (let i = 0; i < attributes.length; i++) {
        const attributeName = attributes[i].name;
        if (attributeName.startsWith("input-")) {
          const uniqueId = `field-${uuidv4()}`;
          element.setAttribute("data-field-id", uniqueId);

          let maxCharacters = element.getAttribute("max-characters");
          let minCharacters = element.getAttribute("min-characters");
          if (attributeName.startsWith("input-img")) {
            // console.log(element.src, "element");
          }
          // let imgSrc = ;
          // if (!maxWords) {
          //   maxWords = null;
          // }
          // min-characters
          // console.log(imgSrc, "imgSrc");
          fields.push({
            tagName: element.tagName,
            attributeName,
            attributeValue: attributes[i].value,
            uniqueId,
            maxCharacters,
            content: element.innerText,
            minCharacters,
            url: attributeName.startsWith("input-img")
              ? element.src
              : attributeName.startsWith("input-bg")
              ? element.style.backgroundImage.slice(5, -2)
              : null,
          });
        }
      }
    });
    let modifiedHtml = new XMLSerializer().serializeToString(htmlContent);
    modifiedHtml = modifiedHtml.replace(/&amp;/g, "&");

    adGeneratorDispatch({
      type: "SET_UPDATE_TEMPLATE",
      value: modifiedHtml,
    });

    adGeneratorDispatch({ type: "SET_INPUT_FEILDS", value: fields });
  };

  useEffect(() => {
    getTemplateDetail({
      variationId: params.variationId,
    });
    adGeneratorDispatch({
      type: "SET_SELECTED_PROJECT",
      value: authState.defaultProjectInfo,
    });
  }, []);
  // console.log(selectedProject,'selectedProject')
  useEffect(() => {
    rawTemplate && extractFields(rawTemplate);
  }, [rawTemplate]);

  return (
    updatetemplate && (
      <Wrapper>
        <TemplateContainer>
          <TemplateWrapper id="zoomClass">
            <div
              ref={parsedHtmlRef}
              className="outerHtml"
              dangerouslySetInnerHTML={{
                __html: `${updatetemplate}`,
              }}
            />
          </TemplateWrapper>
        </TemplateContainer>
        <EditContainer>
          <NavigationComponent>
            {NavElementsData.map((ele) => {
              if (ele.imgUrl) {
                return (
                  <NavElementContainer
                    isActive={
                      imageAdGeneratorState.curentDetailsTab == ele.name
                    }
                    onClick={() => {
                      imageAdGeneratorDispatch({
                        type: "SET_CURRENT_DETAILS_TAB",
                        value: ele.name,
                      });
                    }}
                  >
                    <img src={ele.imgUrl} />
                    <p>{ele.name}</p>
                  </NavElementContainer>
                );
              } else {
                return <Divider />;
              }
            })}
            <Divider />
            <SaveButton
              isSaved={savedState}
              onClick={() => {
                getInsertTemplate({
                  variationId: rawTemplate.id,
                  html: updatetemplate,
                  brandName: authState.defaultProjectInfo.projectName,
                });
                setSavedState(true);
                setTimeout(() => {
                  setSavedState(false);
                }, 3000);
              }}
            >
              {savedState ? "Saved" : "Save"}
            </SaveButton>
          </NavigationComponent>
          <EditComponent>{renderEditUi()}</EditComponent>
        </EditContainer>
      </Wrapper>
    )
  );
};

export default EditResult;
