import { useEffect, useRef, useState } from "react";
import { countriesList } from "../../../../../../../../utils/countryData";
import languages from "../../../../../../../../utils/languages";
import statics from "../../../../../statics";
import {
  DropdownContainer,
  Input,
  Item,
  LangName,
  List,
  SearchBar,
  AddPersona,
  SearchBarContainer,
} from "./index.styled";
import { useAdGenerator } from "../../../../../context/adGeneration";
import { customToast } from "../../../../../../../../utils/toast-utils";

function Dropdown({
  dropdownFor,
  onSelect,
  onDeselect,
  selectedItems,
  withoutSearchBar = false,
  filteringText,
}) {
  const { steps } = statics;
  // const { occupations } = steps[4]
  const [list, setList] = useState();
  const [filteredList, setFilteredList] = useState();
  const searchBarRef = useRef(null);
  const checkBoxesRefs = useRef([]);
  const {
    adGeneratorState: { occupationItem },
    adGeneratorDispatch,
  } = useAdGenerator();

  const filterList = () => {
    setFilteredList(
      list.filter((item) =>
        item.toLowerCase().includes(searchBarRef.current.value.toLowerCase())
      )
    );
  };

  const handleChange = (e, item) => {
    if (e.target.checked) onSelect(item);
    else onDeselect(item);
  };

  const handleClickOnLabel = (item, index) => {
    const target = checkBoxesRefs.current[index];
    if (!target.checked) {
      target.checked = true;
      onSelect(item);
    } else {
      target.checked = false;
      onDeselect(item);
    }
  };

  const renderList = (listOfItems) => {
    return listOfItems?.map((item, index) => {
      return (
        <Item
          key={index}
          // onClick={(e) => {
          //   e.stopPropagation();
          // }}
          onBlur={(e) => e.stopPropagation()}
          onClick={(e) =>
            dropdownFor != "occupation" ? onSelect(item) : e.stopPropagation()
          }
        >
          {dropdownFor == "occupation" && (
            <Input
              type={"checkbox"}
              checked={selectedItems?.includes(item)}
              onChange={(e) => handleChange(e, item)}
              ref={(el) => (checkBoxesRefs.current[index] = el)}
            />
          )}
          <LangName
            onClick={() =>
              dropdownFor == "occupation" && handleClickOnLabel(item, index)
            }
          >
            {item}
          </LangName>
        </Item>
      );
    });
  };

  const filterListByText = () => {
    if (filteringText && list) {
      setFilteredList(
        list.filter((item) =>
          item.toLowerCase().includes(filteringText.toLowerCase())
        )
      );
    }
  };

  const AddNewPersona = () => {
    if (searchBarRef.current.value.toLowerCase()) {
      onSelect(searchBarRef.current.value.toLowerCase());
      adGeneratorDispatch({
        type: "SET_OCCUPATION_ITEM",
        value: [searchBarRef.current.value.toLowerCase(), ...occupationItem],
      });
    } else {
      customToast({ message: "Please enter a persona to add", type: "error" });
    }
  };

  useEffect(() => {
    switch (dropdownFor) {
      case "language":
        setList(languages.map((item) => item.name));
        break;

      case "occupation":
        setList(occupationItem);
        break;

      case "country":
        setList(countriesList.map((country) => country.name.common));
        break;

      default:
        break;
    }
  }, []);

  useEffect(() => {
    filterListByText();
  }, [filteringText]);


  return (
    <DropdownContainer onClick={(e)=>e.stopPropagation()}>
      {!withoutSearchBar && (
        <SearchBarContainer>
          <SearchBar
            ref={searchBarRef}
            type={"text"}
            placeholder={`Search ${dropdownFor}...`}
            onChange={() => {
              filterList();
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onBlur={(e) => e.stopPropagation()}
          />
          {dropdownFor == "occupation" && (
            <AddPersona
              isDisabled={searchBarRef.current?.value?.length == 0}
              onClick={AddNewPersona}
            >
              Add
            </AddPersona>
          )}
        </SearchBarContainer>
      )}
      <List withoutSearchBar={withoutSearchBar}>
        {!filteredList && list && renderList(list)}
        {filteredList && renderList(filteredList)}
      </List>
    </DropdownContainer>
  );
}

export default Dropdown;
