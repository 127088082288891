import React from "react";
import EditResult from "./components/EditResult";
import Results from "./components/Results";
import { getSearchParams } from "../../../../../../utils/helper";
import { MaxSizeWrapper, Wrapper } from "./index.styled";

const ResultPage = () => {
  const RenderComponents = () => {
    const params = getSearchParams(window.location.search);

    if (params.id) {
      if (params.variationId) {
        return (
          <Wrapper style={{ padding: "0px" }}>
            <EditResult />
          </Wrapper>
        );
      } else {
        return (
          <Wrapper>
            <MaxSizeWrapper>
              <Results />
            </MaxSizeWrapper>
          </Wrapper>
        );
      }
    }
  };

  return RenderComponents();
};

export default ResultPage;
