import styled from "styled-components";
import orangeCircle from "../../../../../media/Dashboard/orangeCircle.svg";

export const PlansWrapper = styled.div`
  height: 100%;
`;
export const PlansTitle = styled.div`
  color: #000;
  font-size: 22px;
  font-weight: 600;
  line-height: 38px;
`;
export const PlansInfo = styled.div`
  color: rgba(28, 28, 28, 0.5);
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
`;
export const PlansRange = styled.div`
  display: flex;
  gap: 16px;
  color: #313131;
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
  /* opacity: 0.3; */
`;
export const PlansRangeWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
`;
export const PeriodRange = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;
  cursor: pointer;
  p {
    width: 16px;
    height: 16px;
    border: 1px solid #313131;
    border-radius: 50%;
    padding: 4px;
    background: ${(props) =>
      props.isSelected ? `url(${orangeCircle})` : "none"};
    background-repeat: no-repeat;
    background-position: center;
  }
`;
export const PlanListWrap = styled.div`
  display: flex;
  width: 100%;
  overflow-x: auto;
  gap: 10px;
`;
export const PlanListContainer = styled.div`
  border-radius: var(--8, 8px);
  background: #fff;
  width: 584px;
  height: 512px;
  border: ${(props) => (props.isActive ? "1px solid #ff6f61" : "")};
`;
export const PlanHeader = styled.div`
  border-bottom: 1px solid #dadfe4;
  padding: 24px 24px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PlanName = styled.div`
  color: #313131;
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
`;
export const PlanActive = styled.div`
  border-radius: 41px;
  border: 1px solid #717171;
  display: flex;
  padding: 2px 8px;
  align-items: center;
  gap: 5px;
  p {
    width: 8px;
    height: 8px;
    background: #717171;
    border-radius: 50%;
  }
`;
export const PlanDetails = styled.div`
  color: #7d7d7d;
  font-size: 13px;
  font-weight: 500;
  line-height: 150%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* width: 100%; */
`;
export const PlanStage = styled.div`
  display: flex;
  column-gap: 10px;
`;
export const PlanHeadDiv = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: 69%;
`;
export const PlanAmount = styled.div`
  color: #313131;
  font-size: 32px;
  font-weight: 600;
  line-height: 150%;
  display: flex;
  column-gap: 8px;
  p {
    color: #7d7d7d;
    font-size: 14px;
    font-weight: 500;
  }
`;
export const PlansContainer = styled.div`
  padding: 16px 24px 24px 24px;
`;
export const FeatureDiv = styled.div`
  color: rgba(28, 28, 28, 0.8);
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  padding-bottom: 4px;
`;
export const FeatureContains = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-top: 32px;
  height: 240px;
`;
export const FeatureListing = styled.div`
  display: flex;
  gap: 10px;
  p {
    color: #7d7d7d;
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    span {
      color: #4f4f4f;
      font-size: 14px;
      font-weight: 600;
      line-height: 150%;
    }
  }
`;

export const MostPopular = styled.div`
  position: absolute;
  top: 0;
  right: 22px;
  background: linear-gradient(180deg, rgb(246, 247, 251) 50%, #fff 50%);
  width: 92px;
  p {
    background: linear-gradient(100deg, #ff6f61 13.09%, #f8913a 89.53%);
    background-clip: text;
    -webkit-background-clip: text;
    font-size: 14px;
    font-style: italic;
    font-weight: 600;
    line-height: 20px;
    -webkit-text-fill-color: transparent;
  }
`;

export const MostPopularDiv = styled.div`
  position: relative;
  height: 523px;
  display: flex;
  align-items: end;
`;

export const UpgradePro = styled.div`
  border-radius: 20px;
  background: #ff711e;
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  margin-top: 30px;
  cursor: pointer;
  pointer-events: ${(props) => props.isActive && 'none'};
  opacity:  ${(props) => props.isActive && '0.5'};
`;
export const ScrollDiv = styled.div`
  overflow-y: auto;
`;
