import React, { useEffect, useState } from "react";
import {
  FeatureContains,
  FeatureDiv,
  FeatureListing,
  MostPopular,
  MostPopularDiv,
  PeriodRange,
  PlanActive,
  PlanAmount,
  PlanDetails,
  PlanHeadDiv,
  PlanHeader,
  PlanInside,
  PlanListContainer,
  PlanListWrap,
  PlanName,
  PlanStage,
  PlansContainer,
  PlansInfo,
  PlansRange,
  PlansRangeWrap,
  PlansTitle,
  PlansWrapper,
  ScrollDiv,
  UpgradePro,
} from "./index.styled";
import crossMark from "../../../../../media/Dashboard/cross-mark.svg";
import checkMark from "../../../../../media/Dashboard/check-mark.svg";
import { useAccount } from "../../context/account";
import { useAuth } from "../../../../../context/auth";
import { useGetAccount } from "../../../../hooks/useGetAccount";
import { useSubscription } from "../../../../hooks/useSubscription";
import Skeleton from "react-loading-skeleton";

const PlansBilling = () => {
  const { accountState, accountDispatch } = useAccount();
  const { authState, authDispatch } = useAuth();
  const accountInfoHook = useGetAccount();
  const [selectedType, setSelectedType] = useState("Yearly");
  const subscriptionHook = useSubscription();

  const planData = [
    {
      plan: "Basic Plan",
      planRate: "FREE",
      planDetail:
        "Essential tools for starters—track, search, and explore with ease.",
      content: [
        {
          heading: "Projects",
          detail: "Manage up to 2 projects",
          access: true,
        },
        {
          heading: "AdSpy Access",
          detail: "Free access with basic",
          access: true,
        },
        {
          heading: "Keywords Tracking",
          detail: "Track up to 10 keywords",
          access: true,
        },
        {
          heading: "Competitors Tracking",
          detail: "Monitor up to 5 competitors",
          access: true,
        },
        {
          heading: "Ad Generation",
          detail: "Not included in this plan",
          access: false,
        },
        {
          heading: "Ad Optimization",
          detail: "Not included in this plan",
          access: false,
        },
      ],
    },
  ];
  useEffect(() => {
    accountDispatch({ type: "SET_CURRENT_PAGE", value: "plans-billing" });
    accountInfoHook.plansBillingMutate(authState.userLocation.currency);
  }, []);

  const upgradeFunction = (selectPlan) => {
    subscriptionHook.CreateSubscriptionMutate({
      planId: selectPlan.id,
    });
  };

  return (
    <PlansWrapper>
      <PlansTitle>Plans and billing</PlansTitle>
      <PlansRangeWrap>
        <PlansInfo>Manage your plans and billing details</PlansInfo>
        {!accountInfoHook.isplansBillingLoading && <PlansRange>
          <PeriodRange
            isSelected={selectedType == "Monthly"}
            onClick={() => setSelectedType("Monthly")}
          >
            <p></p>Monthly
          </PeriodRange>
          <PeriodRange
            isSelected={selectedType == "Yearly"}
            onClick={() => setSelectedType("Yearly")}
          >
            <p></p>Annual (17% off)
          </PeriodRange>
        </PlansRange>}
      </PlansRangeWrap>
      <PlanListWrap>
        {!accountInfoHook.isplansBillingLoading ? (
          accountState?.isPlansList &&
          accountState?.isPlansList.map((item, index) => {
            const buttonText =
              (authState.plan.planName == "free"
                ? "Basic plan" == item.nickname
                : authState.plan.planName == item.nickname) &&
              authState.plan.active
                ? "Subscribed"
                : `${
                    authState.plan.planName.includes("basic")
                      ? "Subscribe"
                      : "Upgrade"
                  } to ${
                    item.nickname.includes("ADSPYDER-TEST-")
                      ? item.nickname.substring(
                          item.nickname.lastIndexOf("-") + 1
                        )
                      : item.nickname
                  }`;

            return (
              item.id.includes(selectedType) && (
                <MostPopularDiv>
                  <PlanListContainer isActive={item.amount != "0"}>
                    <PlanHeader>
                      <PlanHeadDiv>
                        <PlanStage>
                          <PlanName>
                            {item.nickname.includes("ADSPYDER-TEST-")
                              ? item.nickname.substring(
                                  item.nickname.lastIndexOf("-") + 1
                                )
                              : item.nickname}
                          </PlanName>
                          <PlanActive>
                            <p></p>Active
                          </PlanActive>
                        </PlanStage>
                        <PlanDetails>{item.planDetail}</PlanDetails>
                      </PlanHeadDiv>
                      <PlanAmount>
                        {item.amount == "0" ? "FREE" : `$${item.amount}`}
                        <p>
                          {item.amount != "0" &&
                            (item.id.includes("Yearly")
                              ? "per year"
                              : "per month")}
                        </p>
                        {/* <p>per month</p> */}
                      </PlanAmount>
                    </PlanHeader>
                    <PlansContainer>
                      <FeatureDiv>FEATURES</FeatureDiv>
                      <PlanDetails>
                        {item.amount == "0"
                          ? "Everything in our free plan...."
                          : "Everything in our Basic plan, plus...."}
                      </PlanDetails>
                      <ScrollDiv>
                        <FeatureContains>
                          {item.content.map((data) => (
                            <FeatureListing>
                              <img src={checkMark} />
                              <p>{data}</p>
                            </FeatureListing>
                          ))}
                        </FeatureContains>
                      </ScrollDiv>
                      <UpgradePro
                        onClick={() => upgradeFunction(item)}
                        isActive={buttonText == "Subscribed"}
                      >
                        {buttonText}
                      </UpgradePro>
                    </PlansContainer>
                  </PlanListContainer>
                  {item.amount != "0" && (
                    <MostPopular>
                      <p>Most Popular </p>
                    </MostPopular>
                  )}
                </MostPopularDiv>
              )
            );
          })
        ) : (
          <PlanListContainer>
            <Skeleton
              style={{
                width: "100%",
                height:'100%'
              }}
            />
          </PlanListContainer>
        )}
      </PlanListWrap>
    </PlansWrapper>
  );
};

export default PlansBilling;
