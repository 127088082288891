import React, { useState, useEffect, useRef } from "react";

import {
  PopupBodyContainer,
  BodyHeader,
  CountrySearchBar,
  CountriesListContainer,
  CheckboxGroup,
  CheckBoxContainer,
  CountriesContainer,
  DropdownContainer,
  DropdownButton,
  DropDownImage,
  Flag,
  CountryName,
  FlagContainer,
  Country,
} from "./index.styled";

import UpArrow from "../../../../../../../../../../../../../media/Dashboard/uparrow.png";
import DownArrow from "../../../../../../../../../../../../../media/Dashboard/downarrow.png";

import { countriesList } from "../../../../../../../../../../../../../utils/countryData";
import { useSearchParams } from "react-router-dom";
import { useOutsideAlerter } from "../../../../../../../../../../../../../utils/outsiderAlert";
import { useTextAdGenerator } from "../../../../../../../../../context/textAdGeneration";
import searchIcon from "../../../../../../../../../../../../../media/Dashboard/searchIcon.svg";
import {useAdGenerator} from "../../../../../../../../../../context/adGeneration"
import { GetCountryData } from "../../../../../../../../../../../../../utils/getCountryData";

const CountryDropDown = () => {
  const targetRef = useRef(null);
  const {
    textAdGeneratorState,
    textAdGeneratorDispatch,
  } = useTextAdGenerator();

  const { adGeneratorState: {isAdDetails}, adGeneratorDispatch } = useAdGenerator()

  const [filteredCountries, setFilteredCountries] = useState([]);
  const [show, setShow] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const handleCountrySearch = (e) => {
    let searchTerm = e.target.value;
    let filteredCountriesList = [];

    filteredCountriesList = countriesList.filter((country, idx) => {
      return (
        country.name.common.toLocaleLowerCase() ==
          searchTerm.toLocaleLowerCase() ||
        country.name.common
          .toLocaleLowerCase()
          .includes(searchTerm.toLocaleLowerCase())
      );
    });

    setFilteredCountries(filteredCountriesList);
  };

  let updatePosition = () => {
    if (targetRef.current) {
      const rect = targetRef.current.getBoundingClientRect();
    }
  };

  const updateTargetCountry = (country) => {
    const isCountryExists = isAdDetails?.adDetails.locations.some(
      (count) => count != null && count.code === country.code
    );

    if (!isCountryExists) {
      const updatedlocationsList = [...isAdDetails?.adDetails.locations];
      updatedlocationsList.push(country);
      const updatedLocation = {
        ...isAdDetails,
        adDetails: {
          ...isAdDetails.adDetails,
          locations: updatedlocationsList,
        },
      };
      adGeneratorDispatch({
        type: "SET_AD_DETAILS",
        value: updatedLocation,
      });
    }
    searchParams.set("country", country.code ? country?.name.common : "global");
    setSearchParams(searchParams);
  };

  useEffect(() => {
    updatePosition();

    window.addEventListener("resize", updatePosition);
    window.addEventListener("scroll", updatePosition);

    return () => {
      window.removeEventListener("resize", updatePosition);
      window.removeEventListener("scroll", updatePosition);
    };
  }, []);

  useOutsideAlerter(targetRef, setShow);

  return (
    <DropdownContainer ref={targetRef} isDropdownOpened={show}>
      <DropdownButton
        onClick={() => {
          setShow((prev) => !prev);
          updatePosition();
        }}
      >
        <img src={searchIcon} />
        <CountrySearchBar
          onChange={handleCountrySearch}
          placeholder="Search country"
        />
      </DropdownButton>
      {show && (
        <PopupBodyContainer height={30} className="country-dropdown-options">
          <CountriesListContainer>
            {" "}
            {filteredCountries.length > 0
              ? filteredCountries.map((country, idx) => {
                  return (
                    <CheckboxGroup>
                      <CountriesContainer>
                        {
                          // country.countries.map((country, id) => {
                          //     return
                          <Country
                            key={idx}
                            onClick={() => {
                              updateTargetCountry(country);
                              setShow(false);
                            }}
                          >
                            <FlagContainer>
                              <Flag src={country.flags.svg} />
                            </FlagContainer>
                            <CountryName>{country.name.common}</CountryName>
                          </Country>
                          // })
                        }{" "}
                      </CountriesContainer>
                    </CheckboxGroup>
                  );
                })
              : countriesList.map((country, idx) => {
                  return (
                    <CheckboxGroup>
                      <CountriesContainer>
                        {
                          // country.countries.map((country, id) => {
                          //     return
                          <Country
                            key={idx}
                            onClick={() => {
                              updateTargetCountry(country);
                              setShow(false);
                            }}
                          >
                            <FlagContainer>
                              <Flag src={country.flags.svg} />
                            </FlagContainer>
                            <CountryName>{country.name.common}</CountryName>
                          </Country>
                          // })
                        }{" "}
                      </CountriesContainer>
                    </CheckboxGroup>
                  );
                })}{" "}
          </CountriesListContainer>
        </PopupBodyContainer>
      )}
    </DropdownContainer>
  );
};

export default CountryDropDown;
