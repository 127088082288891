import {
  ApplyButton,
  BoldSpan,
  Checkbox,
  CircleCheckbox,
  Clear,
  Content,
  Description,
  FocusPopup,
  Footer,
  Icon,
  ListContainer,
  ListItem,
  NewContainer,
  NewLabel,
  PaddedLine,
  Parameter,
  SortContainer,
  SortFocusContainer,
  Title,
  EmptyListContainer
} from "./index.styled";
import focus from "./assets/focus.svg";
import selected from "./assets/selected.svg";
import tick from "./assets/tick.svg";
import { useEffect, useState, useMemo, useRef } from "react";
import { useAuth } from "../../../../../context/auth";
import {
  useTrackingCompetitors,
  useTrackingKeywords,
} from "./hooks/useSortFocus";
import { getSearchParams } from "../../../../../utils/helper";
import { useLocation, useSearchParams } from "react-router-dom";
import { useOutsideAlerter } from "../../../../../utils/outsiderAlert";
import AddNewPopup from "../../../../components/AddNewPopup";
import { useDashboard } from "../../../../../context/dashboard";
import { useDashboardInfo } from "../../../Dashboard/hooks/useDashboardInfo";

const parameters = [
  { name: "Keyword Focus", programmaticName: "keyword" },
  { name: "Competitor Focus", programmaticName: "competitor" },
];

function SortFocus() {
  const {
    authState: { trackingKeywords, trackingCompetitors, defaultProjectInfo },
  } = useAuth();
  const {
    dashboardState: {
      selectedProject,
      keywordChoices,
      selectedCountry,
      competitorChoices,
    },
    dashboardDispatch,
  } = useDashboard();
  const useDashboardInfoHook = useDashboardInfo();

  // console.log('track', trackingKeywords, trackingCompetitors);

  const location = useLocation();
  const popupRef = useRef(null);
  const [isAddCategory, setAddCategory] = useState(null);

  const [isAddNewPopupOpened, setAddNewPopupOpened] = useState(false);

  const searchParamFromState = getSearchParams(location.search);
  const [searchParams, setSearchParams] = useSearchParams();

  const { searchMode, keywordsArray, competitorsArray } = searchParamFromState;

  const [selectedParameterIndex, setSelectedParameterIndex] = useState(
    searchMode == "keyword"
      ? competitorsArray
        ? 1
        : null
      : keywordsArray
        ? 0
        : null
  );
  const [focussedItems, setFocussedItems] = useState({
    keywords: [],
    competitors: [],
  });
  const [popup, setPopup] = useState(false);

  const { getTrackingKeywords } = useTrackingKeywords();
  const { getTrackingCompetitors } = useTrackingCompetitors();

  useEffect(() => {
    getTrackingKeywords(defaultProjectInfo.projectId);
    getTrackingCompetitors(defaultProjectInfo.projectId);
  }, [defaultProjectInfo]);

  const toggleFocus = (e) => {
    e.stopPropagation(); // Prevent toggle on inner elements
    setPopup((prev) => !prev);
  };
  useOutsideAlerter(popupRef, setPopup);

  const selectParameter = (e, index) => {
    // console.log(index, "index");
    e.stopPropagation();
    setSelectedParameterIndex(index);
  };
  // console.log(selectedParameterIndex, "selectedParameterIndex");

  const onClearFocus = (e) => {
    e.stopPropagation();
    setSelectedParameterIndex(null);
    setFocussedItems({ keywords: [], competitors: [] });
  };

  const handleFocusToggle = (item, type) => {
    setFocussedItems((prev) => {
      const isFocused = prev[type].includes(item);
      const updatedList = isFocused
        ? prev[type].filter((i) => i !== item)
        : [...prev[type], item];
      return { ...prev, [type]: updatedList };
    });
  };

  const closeAddNewPopup = () => {
    setAddNewPopupOpened(false);
  };

  const renderLists = useMemo(() => {
    if (selectedParameterIndex === null) return null;
    const type = selectedParameterIndex === 0 ? "keywords" : "competitors";
    const items =
      selectedParameterIndex === 0 ? trackingKeywords : trackingCompetitors;
      
    if (items.length == 0) {
      return <EmptyListContainer>It seems you haven't set up competitors or keywords yet.</EmptyListContainer>
    }
    return items.map((item, index) => {
      const isFocussed = focussedItems[type].includes(item);

      return (
        <ListItem key={index} onClick={() => handleFocusToggle(item, type)}>
          <Checkbox checked={isFocussed}>
            <Icon src={tick} draggable={false} />
          </Checkbox>
          {item}
        </ListItem>
      );
    });
  }, [
    selectedParameterIndex,
    focussedItems,
    trackingKeywords,
    trackingCompetitors,
  ]);
  // const paramList =
  // selectedParameterIndex == 0 && focussedItems.keywords.length > 0
  //   ? focussedItems.keywords.join(keyword",")
  //   : focussedItems.competitors.join(",");
  const handleSetup = (chosenChoicesIndices, userSelectedList) => {
    if (selectedParameterIndex == 1) {
      const competitorsData = chosenChoicesIndices.map((index) => {
        return {
          domain: competitorChoices[index].domainName,
          source: "suggested",
        };
      });

      const userEnteredCompetitorData = userSelectedList.map((data) => {
        return {
          domain: data,
          source: "user",
          country: selectedCountry,
        };
      });

      useDashboardInfoHook.addCompetitorsForTrackingMutate({
        projectId: selectedProject.id,
        domains: [...competitorsData, ...userEnteredCompetitorData],
      });
    } else {
      const keywordData = chosenChoicesIndices.map((index) => {
        return {
          keyword: keywordChoices[index].keyword,
          source: "suggested",
          country: selectedCountry,
        };
      });

      const userEnteredKeywordData = userSelectedList.map((data) => {
        return {
          keyword: data,
          source: "user",
          country: selectedCountry,
        };
      });

      useDashboardInfoHook.addKeywordsForTrackingMutate({
        projectId: selectedProject.id,
        keywords: [...keywordData, ...userEnteredKeywordData],
      });
    }
    closeAddNewPopup();
  }

  const focusApply = () => {
    if (((selectedParameterIndex == 0) && (focussedItems.keywords.length > 0)) || ((selectedParameterIndex == 1) && (focussedItems.competitors.length > 0))) {
      const { keywords, competitors } = focussedItems;
      const competitorsList = competitors.join(",");
      const keywordsList = keywords.join(",");

      if (selectedParameterIndex === 0 && keywords.length > 0) {
        competitorsArray &&
          searchParams.delete("competitorsArray") &&
          searchParams.delete("sortArray");
        searchParams.set("keywordsArray", keywordsList);
        searchParams.set("sortArray", "keywords");
      } else if (selectedParameterIndex === 1 && competitors.length > 0) {
        keywordsArray &&
          searchParams.delete("keywordsArray") &&
          searchParams.delete("sortArray");
        searchParams.set("competitorsArray", competitorsList);
        searchParams.set("sortArray", "competitors");
      }
      setSearchParams(searchParams);

      setFocussedItems((prevState) => ({
        ...prevState,
        ...(selectedParameterIndex === 0 && focussedItems.keywords.length > 0
          ? { competitors: [] }
          : { keywords: [] }),
      }));

      setPopup(false);
    } else {
      console.log("no keywords or competitors in project")
      setAddNewPopupOpened(true)
    }
  };

  // console.log(focussedItems, trackingKeywords, trackingCompetitors, "focussedItems");
  
  useEffect(() => {
    // if (searchMode === "keyword") {
    // competitorsArray
    //   ? setFocussedItems((prevItems) => ({
    //       ...prevItems,
    //       competitors: competitorsArray.split(","),
    //     }))
    //   : setFocussedItems({ keywords: [], competitors: [] });
    // } else if (searchMode === "url") {
    // keywordsArray
    //   ? setFocussedItems((prevItems) => ({
    //       ...prevItems,
    //       keywords: keywordsArray.split(","),
    //     }))
    //   : setFocussedItems({ keywords: [], competitors: [] });
    // }
    setFocussedItems(() => ({
      keywords: keywordsArray ? keywordsArray.split(",") : [],
      competitors: competitorsArray ? competitorsArray.split(",") : [],
    }));
  }, [location.search, searchMode, keywordsArray, competitorsArray]);

  return (
    <SortFocusContainer ref={popupRef}>
      {isAddNewPopupOpened && <AddNewPopup handleSetup={handleSetup} handleClose={closeAddNewPopup} forKeyword={selectedParameterIndex == 0} />}
      <SortContainer onClick={toggleFocus}>
        Sort Focus
        <Icon src={focus} draggable={false} />
        <NewContainer>
          <NewLabel>new</NewLabel>
        </NewContainer>
      </SortContainer>
      <FocusPopup visibility={popup}>
        <Content>
          <Title>
            SortFocus
            <Clear onClick={onClearFocus}>Clear Focus</Clear>
          </Title>
          {selectedParameterIndex === null && (
            <Description>
              <BoldSpan>Note: </BoldSpan>
              Selected focus will prioritize relevant results at the top.
            </Description>
          )}
          <PaddedLine />
          {parameters.map((parameter, index) => {
            return (
              <>
                <Parameter
                  key={index}
                  onClick={(e) => selectParameter(e, index)}
                >
                  {parameter.name}
                  {selectedParameterIndex === index ? (
                    <Icon src={selected} draggable={false} />
                  ) : (
                    <CircleCheckbox />
                  )}
                </Parameter>
                {index === 0 && <PaddedLine />}
              </>
            );
          })}

          {selectedParameterIndex !== null ? (
            <ListContainer onClick={(e) => e.stopPropagation()}>
              {renderLists}
            </ListContainer>
          ) : (
            <PaddedLine />
          )}
          <Footer>
            <ApplyButton
              isActive={
                (selectedParameterIndex != null) && 
                (((selectedParameterIndex == 0) && (trackingKeywords.length == 0)) ||
                ((selectedParameterIndex == 0) && (trackingKeywords.length > 0) && focussedItems.keywords.length > 0) ||
                ((selectedParameterIndex == 1) && (trackingCompetitors.length == 0)) ||
                ((selectedParameterIndex == 1) && (trackingCompetitors.length > 0) && focussedItems.competitors.length > 0) )
              }
              onClick={focusApply}
            >
              {(((selectedParameterIndex == 0) && (trackingKeywords.length > 0)) || ((selectedParameterIndex == 1) && (trackingCompetitors.length > 0))) ? "Apply" : `Setup ${selectedParameterIndex == 0 ? "keyword" : "Competitor"}`}
            </ApplyButton>
          </Footer>
        </Content>
      </FocusPopup>
    </SortFocusContainer>
  );
}

export default SortFocus;
