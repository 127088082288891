import React from "react";
import { useReducer, createContext, useContext } from "react";

import produce from "immer";
import statics from "../statics";

const initialState = {
  currentPage: "home",
  adGenerationType: null,

  adHistory: [],

  generatedAdId: null,
  currentInputPageIndex: 0,
  goal: null,
  description: null,
  destinationUrl: null,
  keywordSuggestion: [],
  brandDetails: {
    name: null,
    description: null,
    productDetails: null,
    logo: null,
    colors: [],
    images: [],
  },
  targetAudience: {
    gender: null,
    age: { from: 18, to: 24 },
    ageGroup: [],
    languages: "English",
    occupations: [],
    locations: ["India"],
  },
  adType: "socialMedia",
  occupationItem: [],
  // image ad
  isGenerationProcess: null,
  adGroupItem: null,
  isAdDetails: null,
  wesbiteMetaLoading: false,
  selectedIndices: [],
  templateVariations: null,
  updatetemplate: null,
  rawTemplate: null,
  uploadedAsset: {
    assetImg: null,
    propertyType: null,
  },
  scaleValue: null,
  inputFields: null,
  selectedStockImgUpload: {},
  updateStockImage: null,
  selectedColor: null,
  selectedProject: {},
  getWentWrong: false,
  generatingProcesses: statics.processes,
};

const adGeneratorReducer = produce((draft, action) => {
  switch (action.type) {
    case "SET_CURRENT_PAGE":
      draft.currentPage = action.value;
      break;

    case "SET_GENERATED_AD_ID":
      draft.generatedAdId = action.value;
      break;

    case "SET_ADGENERATION_TYPE":
      draft.adGenerationType = action.value;
      break;

    case "SET_KEYWORD_SUGGESTION":
      draft.keywordSuggestion = action.value;
      break;

    case "SET_CURRENT_INPUT_PAGE_INDEX":
      draft.currentInputPageIndex = action.value;
      break;

    case "SET_GOAL":
      draft.goal = action.value;
      break;

    case "SET_DESCRIPTION":
      draft.description = action.value;
      break;

    case "SET_DESTINATION_URL":
      draft.destinationUrl = action.value;
      break;

    case "SET_BRAND_DETAILS":
      draft.brandDetails = { ...draft.brandDetails, ...action.value };
      break;

    case "SET_TARGET_AUDIENCE":
      draft.targetAudience = action.value;
      break;

    case "SET_AD_TYPE":
      draft.adType = action.value;
      break;
    case "SET_WEBSITE_META_LOADING":
      draft.wesbiteMetaLoading = action.value;
      break;

    case "RESET_GOAL":
console.log(draft,'draft',initialState)

Object.assign(draft, initialState);

      break;
    // text ad
    case "SET_GENERATION_PROCESS":
      draft.isGenerationProcess = action.value;
      break;
    case "SET_AD_DETAILS":
      draft.isAdDetails = action.value;
      break;
    case "SET_ADGROUP_ITEM":
      draft.adGroupItem = action.value;
      break;
    case "SET_OCCUPATION_ITEM":
      draft.occupationItem = action.value;
      break;
    case "SET_SELECTED_INDICES":
      draft.selectedIndices = action.value;
      break;
    case "SET_TEMPLATE_VARIATIONS":
      draft.templateVariations = action.value;
      break;
    case "SET_UPDATE_TEMPLATE":
      draft.updatetemplate = action.value;
      break;
    case "SET_RAW_TEMPLATE":
      draft.rawTemplate = action.value;
      break;
    case "SET_UPLOADED_ASSET":
      draft.uploadedAsset = action.value;
      break;
    case "SET_SCALE_VALUE":
      draft.scaleValue = action.value;
      break;
    case "SET_INPUT_FEILDS":
      draft.inputFields = action.value;
      break;
    case "UPDATE_STOCK_IMG_UPLOAD":
      draft.selectedStockImgUpload = action.value;
      break;
    case "UPDATE_STOCK_IMAGE":
      draft.updateStockImage = action.value;
      break;
    case "SET_SELECTED_COLOR":
      draft.selectedColor = action.value;
      break;
    case "SET_SELECTED_PROJECT":
      draft.selectedProject = action.value;
      break;
    case "SET_WENT_WRONG":
      draft.selectedProject = action.value;
      break;
    case "SET_HISTORY_DATA":
      draft.adHistory = action.value;
      break;
    case "UPDATE_PROCESSING_STATUS":
      draft.generatingProcesses[action.value.id].isCompleted =
        action.value.value;
      break;

    default:
      break;
  }
});

const useAdGeneratorReducer = () => {
  return useReducer(adGeneratorReducer, initialState);
};

const AdGeneratorContext = createContext();

export const AdGeneratorProvider = ({ children }) => {
  const [state, dispatch] = useAdGeneratorReducer();
  const value = { adGeneratorState: state, adGeneratorDispatch: dispatch };

  return (
    <AdGeneratorContext.Provider value={value}>
      {children}
    </AdGeneratorContext.Provider>
  );
};

export const useAdGenerator = () => {
  return useContext(AdGeneratorContext);
};
