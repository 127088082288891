import React, {useEffect, useState} from 'react'

import {
    Container,
    SearchContainer,
    ResultsContainer,
    Wrapper,
    HeadingWrapper,
    DomainInput,
    TrackButton,
    InputContainer,
    ClearButton,
    HeadingLeft,
    HeadLeftWrap,
    HeaderRight,
    HowItWork,
    ErrorBox,
    RightIcons,
    ErrorIcon,
    HttpsLabel,
    PlaceholderWithInput
} from "./index.styled"
import Tooltip from 'rc-tooltip';

import PPCRestrictionPopup from '../../../components/PPCRestrictionPopup';
import CloseIcon from "../../../../media/Dashboard/detailsPaneClose.svg"
import { useAuth } from '../../../../context/auth';

import { useDomainTracking } from '../context/domaintracking';
import DomainInputModal from './components/DomainInputModal';
import DomainTable from './components/DomainTable/components/domainTable';
import { useDomainTrackingInfo } from '../hooks/useDomainTrackingInfo';
import tracking from "./assets/tracking.png"
import errorIcon from "./assets/errorIcon.svg"
import { useDashboardInfo } from '../../Dashboard/hooks/useDashboardInfo';
import { useDashboard } from '../../../../context/dashboard';
import AddNewPopup from '../../../components/AddNewPopup';
import CompetitorsTable from '../../Dashboard/components/ProjectOverview/CompetitorsTable';

function isValidDomain(url) {
    const domainAndUrlRegEx = /^(((http|https):\/\/|)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}(:[0-9]{1,5})?(\/.*)?)$/;

    return domainAndUrlRegEx.test(url);
}

const DomainTracking = () => {
    const {domainTrackingState, domainTrackingDispatch} = useDomainTracking()
    const useDomainTrackingInfoHook = useDomainTrackingInfo()

    const {authState, authDispatch} = useAuth()

    const [inputFocussed, setInputFocussed] = useState(false)
    const [error, setError] = useState(false)

    const { dashboardState: {competitorChoices, selectedCountry, selectedProject} } = useDashboard()
    const useDashboardInfoHook = useDashboardInfo()

    useEffect(() => {
        useDomainTrackingInfoHook.getTrackingDomainsMutation()
        domainTrackingDispatch({type: 'SET_DOMAIN_INPUT', value: ""})
    }, [])
    
    const eventEnterHandle = (e) =>{
        if (e.key === 'Enter') {
            domainTrackingDispatch({type: 'SET_DOMAIN_INPUT_LIST', value: [{ domain: domainTrackingState.domainInput, platform: '', country: {}, platformError: '' }]})
            domainTrackingDispatch({type: 'SET_DOMAIN_INPUT_MODAL_OPEN', value: true})
        }
    }

    const headingText = "Domain Tracking  "
    const descriptionText = "Know and track how your target domains are performing in the advertising space. AdSpyder’s Domain Tracking lets you stay on top of your competitors and provide campaign, platform and demographic level insights with just a few taps. "


    const toolTipFunction = ()  =>{
        return (
            <div>
                <div style={{marginBottom: '16px',fontSize: '14px', color: 'var(--black-100, #1C1C1C)',fontWeight: '500'}}>How to use:</div>
                <ul style={{listStyleType: 'decimal',marginLeft: '16px'}}>
                    <li  style={{marginBottom: '16px'}}>Click on “Add Domain” button. In the popup, type in the domain of your choice and select the platform and country from the dropdown. </li>
                    <li  style={{marginBottom: '16px'}}>You can add more domains by using the “Add more domain” option. Once the domains are added, click on the “Setup Tracking” button. </li>
                    <li  style={{marginBottom: '16px'}}>To view the analytics of the tracked domain, simply click on the domain in the table and view campaign and platform level metrics, with geo-location and seasonality indicators. </li>
                </ul>
            </div>
        )
    }

    const tooltipStyle = {
        marginTop: '100px'
    };
    
    const overlayStyle = {

    };
  
    const overlayInnerStyle = {
        backgroundColor: '#FFF7E8',
        color: 'rgba(28, 28, 28, 0.80)',
        padding: '24px 20px 44px 20px',
        fontSize: '14px',
        marginTop: '120px',
        width: '400px',
        maxHeight: '510px',
        paddingBottom: '44px',
        overflowY: 'auto'
    }; 

    const handleInputChange = (e) => {
        let { value } = e.target
        if(!value.startsWith("https://")) value = `https://${value}`
        console.log('url is ', value ,'valid url', isValidDomain(value) )
        domainTrackingDispatch({type: 'SET_DOMAIN_INPUT', value})
        if(domainTrackingState.domainInput.length > 0 )
            setError(!isValidDomain(e.target.value))
    }


    const closeInputModal = () => {
        domainTrackingDispatch({type: 'SET_DOMAIN_INPUT_MODAL_OPEN', value: false})
    }

    const handleSetup = (chosenChoicesIndices, userSelectedList) => {
        const competitorsData = chosenChoicesIndices.map((index) => {
        return {
            domain: competitorChoices[index].domainName,
            source: "suggested",
        };
        });
        const userEnteredCompetitorData = userSelectedList.map((data) => {
        return {
            domain: data,
            source: "user",
            country: selectedCountry,
        };
        });
        useDashboardInfoHook.addCompetitorsForTrackingMutate({
        projectId: selectedProject.id,
        domains: [...competitorsData, ...userEnteredCompetitorData],
        });
        closeInputModal();
    };

    return(
        <Container>
            <Wrapper>
                <CompetitorsTable forDomainTracking={true} />
            </Wrapper>
        </Container>
    )

    return <Container>
                {authState.isPPCRestrictedPopup && 
                    <ResultsContainer>
                        <PPCRestrictionPopup/>
                    </ResultsContainer>
                }
                {
                    domainTrackingState.domainInputModalOpen && 
                    <AddNewPopup
                        handleSetup={handleSetup}
                        handleClose={closeInputModal}
                        forKeyword = {false}
                    />
                }
               { !domainTrackingState.domainTableOpen && <Wrapper isNavCollapsed={authState.isNavCollapsed}>
                    <HeadingWrapper>
                        <HeadingLeft>
                            <HeadLeftWrap>
                            <h2>{headingText}</h2>
                            <Tooltip 
                                trigger={['hover']}  
                                style={tooltipStyle}
                                overlayStyle={overlayStyle}
                                overlayInnerStyle={overlayInnerStyle} 
                                showArrow={false}
                                arrowContent={null}
                                overlay={toolTipFunction()}
                            >
                                <HowItWork>Quick guide</HowItWork>
                            </Tooltip>
                            </HeadLeftWrap>
                            <p>{descriptionText}</p>
                        </HeadingLeft>
                        <HeaderRight>
                            <img src={tracking} />
                        </HeaderRight>
                    </HeadingWrapper>
                    <SearchContainer isNavCollapsed={authState.isNavCollapsed}>
                        <PlaceholderWithInput>
                            <HttpsLabel>https://</HttpsLabel>
                            <InputContainer isFocussed={inputFocussed} hasError={error} >
                                <DomainInput
                                    value={domainTrackingState.domainInput.startsWith("https://") ? domainTrackingState.domainInput.split("https://")[1] : domainTrackingState.domainInput}
                                    placeholder='Enter a specific domain to track' 
                                    onChange={handleInputChange}    
                                    onKeyDown={(e) => {
                                        eventEnterHandle(e)
                                    }}
                                    onFocus={() => setInputFocussed(true)}
                                    onBlur={() => setInputFocussed(false)}
                                />
                                <RightIcons>
                                    {domainTrackingState.domainInput.length > 0 && <ClearButton  onClick={()=>{domainTrackingDispatch({type: 'SET_DOMAIN_INPUT', value: ""})}} src={CloseIcon}/>}
                                    {error && <ErrorIcon src={errorIcon} alt=""/>}
                                </RightIcons>
                            </InputContainer>
                        </PlaceholderWithInput>
                        
                        <TrackButton onClick={()=>{
                            domainTrackingDispatch({type: 'SET_DOMAIN_INPUT_LIST', value: [{ domain: domainTrackingState.domainInput, platform: '', country: {}, platformError: '' }]})
                            domainTrackingDispatch({type: 'SET_DOMAIN_INPUT_MODAL_OPEN', value: true})}}>
                            Track domain
                        </TrackButton>
                    </SearchContainer>
                    {error && <ErrorBox>Enter a valid domain</ErrorBox>}
                </Wrapper>}
                { domainTrackingState.domainTableOpen && 
                <DomainTable/>
                }
            </Container>
};
    

export default DomainTracking