import AnalyzingLoader from "../../../AnalyzingLoader";
import {
  Age,
  BigContainer,
  Choice,
  Clear,
  Container,
  Country,
  CountryDropdownContainer,
  DropdownContainer,
  FlagContainer,
  FlexContainer,
  Gender,
  Icon,
  Image,
  Input,
  Label,
  LocationContainer,
  MapContainer,
  Next,
  NextContainer,
  Property,
  Remove,
  SearchBar,
  SearchBarContainer,
  SelectedCountries,
  SmallContainer,
} from "./index.styled";
import people from "./assets/people.svg";
import { useEffect, useRef, useState } from "react";
import statics from "../../../../statics";
import dropdown from "./assets/dropdown.svg";
import search from "./assets/search.svg";
import close from "./assets/close.svg";
import clear from "./assets/clear.svg";
import Mapchart from "./Mapchart";
import { countriesList } from "../../../../../../../utils/countryData";
import { CircleFlag } from "react-circle-flags";
import { useAdGenerator } from "../../../../context/adGeneration";
import Dropdown from "./Dropdown";
import {useOutsideAlerter} from "../../../../../../../utils/outsiderAlert"

function TargetAudience({ onNext }) {
  const { steps } = statics;
  const { genders, ageGroups } = steps[4];
  const countrySearchRef = useRef(null);
  const personDropdownRef = useRef(null);
  const languageDropwDownRef = useRef(null);
  // const countryDropwDownRef = useRef(null);

  const {
    adGeneratorState: { targetAudience },
    adGeneratorDispatch,
  } = useAdGenerator();

  const [isLanguageDropdownActive, setLanguageDropdownActive] = useState(false);
  const [isOccupationDropdownActive, setOccupationDropdownActive] =
    useState(false);
  const [countryFilteringText, setCountryFilteringText] = useState("");

  const toggleDropdown = (name) => {
    switch (name) {
      case "language":
        if (isLanguageDropdownActive && !targetAudience.languages)
          return;

        setLanguageDropdownActive(!isLanguageDropdownActive);
        break;

      case "occupation":
        // if (isOccupationDropdownActive && !targetAudience.occupations.length)
        //   return;
        setOccupationDropdownActive(!isOccupationDropdownActive);
        break;
    }
  };

  const handleAgeGenderSelection = (property, value) => {
    adGeneratorDispatch({
      type: "SET_TARGET_AUDIENCE",
      value: {
        ...targetAudience,
        [property]:
          property === "ageGroup"
            ? targetAudience.ageGroup?.includes(value)
              ? targetAudience.ageGroup.filter((item) => item !== value)
              : [...targetAudience[property], value]
            : value,
      },
    });
  };

  const handleNext = () => {
    onNext();
  };

  const handleSelectItem = (property, value) => {
    adGeneratorDispatch({
      type: "SET_TARGET_AUDIENCE",
      value: {
        ...targetAudience,
        [property]: property == 'languages' ? value : [...targetAudience[property], value],

      },
    });
    setLanguageDropdownActive(false)

  };
  const handleDeselectItem = (property, value) => {

    adGeneratorDispatch({
      type: "SET_TARGET_AUDIENCE",
      value: {
        ...targetAudience,
        // [property]: targetAudience[property].filter((item) => item !== value),
        [property]: property == 'languages'
        ? (targetAudience[property] == value ? null : targetAudience[property])
        : targetAudience[property].filter((item) => item !== value),
      },
    });
  };

  useEffect(() => {
    const targetedPromotion =
      document.querySelector("body").lastChild.previousElementSibling;
    if (targetedPromotion.children[1]?.innerText?.includes("Syncfusion"))
      targetedPromotion.remove();
  }, []);



  useOutsideAlerter(personDropdownRef, setOccupationDropdownActive);
  useOutsideAlerter(languageDropwDownRef, setLanguageDropdownActive);
  // useOutsideAlerter(countryDropwDownRef, setCountryFilteringText, "");

  return (
    <Container>
      {/* <AnalyzingLoader text={"Running the AI model..."} /> */}
      <Property>
        <Label>Age and Gender</Label>
        <BigContainer>
          <Gender>
            {genders?.map((gender, index) => {
              return (
                <Choice
                  key={index}
                  isSelected={targetAudience.gender === gender}
                  onClick={() => handleAgeGenderSelection("gender", gender)}
                >
                  {gender}
                </Choice>
              );
            })}
          </Gender>
          <Gender>
            {ageGroups?.map((group, index) => {
              return (
                <Choice
                  key={index}
                  isSelected={targetAudience.ageGroup.includes(group)}
                  onClick={() => handleAgeGenderSelection("ageGroup", group)}
                >
                  {group}
                </Choice>
              );
            })}
          </Gender>
          <Icon src={people} draggable={false} />
        </BigContainer>
      </Property>
      <FlexContainer>
        <Property>
          <Label>Language</Label>
          <SmallContainer
            ref={languageDropwDownRef}
            onClick={() => toggleDropdown("language")}
            onBlur={(e) => {
              if (isLanguageDropdownActive) setLanguageDropdownActive(false);
            }}
          >
            {targetAudience.languages ? targetAudience.languages : "Select atleast one.."}
            {/* {`${
              targetAudience.languages.length > 1
                ? `${targetAudience.languages[0]} + ${
                    targetAudience.languages.length - 1
                  }`
                : targetAudience.languages.length < 1
                ? "Select atleast one.."
                : targetAudience.languages[0]
            }`} */}
            <DropdownContainer>
              <Image src={dropdown} draggable={false} />
            </DropdownContainer>
            {isLanguageDropdownActive && (
              <Dropdown
                dropdownFor={"language"}
                onSelect={(language) => {
                  handleSelectItem("languages", language);
                }}
                // onDeselect={(language) => {
                //   handleDeselectItem("languages", language);
                // }}
                selectedItems={targetAudience.languages}
              />
            )}
          </SmallContainer>
        </Property>
        <Property>
          <Label>Persona</Label>
          <SmallContainer
            ref={personDropdownRef}
            onClick={() => toggleDropdown("occupation")}
            onBlur={(e) => {
              if (isOccupationDropdownActive)
                setOccupationDropdownActive(false);
            }}
          >
            {`${
              targetAudience.occupations.length > 1
                ? `${targetAudience.occupations[0]} + ${
                    targetAudience.occupations.length - 1
                  }`
                : targetAudience.occupations.length < 1
                ? "Select atleast one.."
                : targetAudience.occupations[0]
            }`}
            <DropdownContainer>
              <Image src={dropdown} draggable={false} />
            </DropdownContainer>
            {isOccupationDropdownActive && (
              <Dropdown
                dropdownFor={"occupation"}
                onSelect={(occupation) => {
                  handleSelectItem("occupations", occupation);
                }}
                onDeselect={(occupation) => {
                  handleDeselectItem("occupations", occupation);
                }}
                selectedItems={targetAudience.occupations}
              />
            )}
          </SmallContainer>
        </Property>
      </FlexContainer>
      <Property>
        <Label>Location</Label>
        <LocationContainer>
          <CountryDropdownContainer>
            <SearchBarContainer>
              <Image src={search} draggable={false} />
              <SearchBar
                placeholder="Search city, state or country"
                onChange={(e) => setCountryFilteringText(e.target.value)}
                ref={countrySearchRef}
              />

              {countryFilteringText !== "" && (
                <>
                  <Clear
                    src={clear}
                    draggable={false}
                    onClick={() => {
                      countrySearchRef.current.value = "";
                      setCountryFilteringText("");
                    }}
                  />
                  <Dropdown
                    dropdownFor={"country"}
                    onSelect={(location) => {
                      handleSelectItem("locations", location);
                    }}
                    onDeselect={(location) => {
                      handleDeselectItem("locations", location);
                    }}
                    selectedItems={targetAudience.locations}
                    withoutSearchBar
                    filteringText={countryFilteringText}
                  />
                </>
              )}
            </SearchBarContainer>
            <SelectedCountries>
              {targetAudience.locations.map((country) => {
                const data = countriesList.find(
                  (c) => c.name.common === country
                );
                return (
                  <Country>
                    <FlagContainer>
                      <CircleFlag
                        countryCode={data.code.toLowerCase()}
                        height="24"
                      />
                    </FlagContainer>
                    {country}
                    <Remove
                      src={close}
                      draggable={false}
                      onClick={() => handleDeselectItem("locations", country)}
                    />
                  </Country>
                );
              })}
            </SelectedCountries>
          </CountryDropdownContainer>
          <MapContainer>
            <Mapchart />
          </MapContainer>
        </LocationContainer>
      </Property>
      <NextContainer>
        <Next
          isDisabled={
            !targetAudience.gender ||
            !(targetAudience.ageGroup.length > 0) ||
            !(targetAudience.languages.length > 0) ||
            !(targetAudience.occupations.length > 0) ||
            !(targetAudience.locations.length > 0)
          }
          onClick={handleNext}
        >
          Next
        </Next>
      </NextContainer>
    </Container>
  );
}

export default TargetAudience;
